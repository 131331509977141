<template>
  <div
    v-loading="transactionsLoading || overviewLoading"
    class="dropdown-group-table"
    element-loading-background="rgba(233, 234, 235, 0.8)"
  >
    <el-row class="pc-row pc-header transaction-align">
      <el-col
        v-for="header in transactionsHeaders"
        :key="header.value"
        :span="4"
        class="pc-cell"
      >
        {{ header.label }}
      </el-col>
    </el-row>
    <el-row
      v-for="data in transactionData"
      :key="data.id"
      class="pc-row transaction-align"
    >
      <el-col
        v-for="header in transactionsHeaders"
        :key="header.value"
        :span="4"
        class="pc-cell"
      >
        {{ getCellValue(header, data) }}
      </el-col>
    </el-row>

    <hr color="#e0e0e0" size="1" />

    <el-row class="pc-row pc-header">
      <el-col
        v-for="header in overviewHeaders"
        :key="header.value"
        :span="4"
        class="pc-cell"
      >
        {{ header.label }}
      </el-col>
    </el-row>
    <el-row class="pc-row">
      <el-col
        v-for="header in overviewHeaders"
        :key="header.value"
        :span="4"
        class="pc-cell"
      >
        {{ getCellValue(header, overviewData) }}
      </el-col>
    </el-row>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'

export default {
  props: {
    row: {
      type: Object,
      require: true,
      default: () => ({})
    }
  },
  data() {
    return {
      transactionsHeaders: [
        {
          label: this.$t(
            'pages.reports.statistics.customers.transaction_number'
          ),
          value: 'transaction_number'
        },
        {
          label: this.$t('pages.reports.statistics.customers.total_cart_items'),
          value: 'total_cart_items'
        },
        {
          label: this.$t('pages.reports.statistics.all.net_revenue'),
          value: 'net_revenue',
          type: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.all.revenue'),
          value: 'revenue',
          type: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.all.discount'),
          value: 'discount_amount',
          type: 'currency'
        }
      ],
      overviewHeaders: [
        {
          label: this.$t('pages.reports.statistics.customers.top_seller'),
          value: 'product_name'
        },
        {
          label: this.$t('pages.reports.statistics.customers.top_branch'),
          value: 'branch_name'
        },
        {
          label: this.$t('pages.reports.statistics.customers.top_sale_rep'),
          value: 'top_sale_rep'
        },
        {
          label: this.$t('pages.reports.statistics.customers.amount_returns'),
          value: 'refunded'
        },
        {
          label: this.$t('pages.reports.statistics.customers.profit_made'),
          value: 'profit_made'
        }
      ],
      transactionsLoading: false,
      overviewLoading: false,
      transactionData: [],
      overviewData: {}
    }
  },
  computed: {
    currentBranchNumber() {
      return this.$store.getters['Config/currentBranchNumber']
    },
    currentLocation() {
      return this.$store.getters['Config/currentLocation']
    }
  },
  created() {
    this.customersTransactionTable()
    this.customersOverviewTable()
  },
  methods: {
    getQuery() {
      const query = {
        customer_id: this.row.id,
        currency: this.row.currency
      }

      if (
        this.currentBranchNumber !== null &&
        this.currentBranchNumber !== undefined
      ) {
        query.branch_number = this.currentBranchNumber
      }

      return query
    },
    async customersTransactionTable() {
      const query = this.getQuery()

      this.transactionsLoading = true

      try {
        const { data } = await th.analytics().getCustomersTransaction(query)
        this.transactionData = safeGet(data[0], 'values') || []
      } catch (err) {
        this.$message.error(
          this.$t(
            'pages.reports.statistics.customers.errors.transactions_fetch'
          )
        )
        this.transactionData = []
      } finally {
        this.transactionsLoading = false
      }
    },
    async customersOverviewTable() {
      const query = this.getQuery()

      this.overviewLoading = true

      try {
        const { data } = await th.analytics().getCustomersOverview(query)
        const values = safeGet(data[0], 'values')
        this.overviewData = values[0] || {}
      } catch (err) {
        this.$message.error(
          this.$t('pages.reports.statistics.customers.errors.overview_fetch')
        )
        this.overviewData = {}
      } finally {
        this.overviewLoading = false
      }
    },
    getCellValue(header, row) {
      const cellValue = safeGet(row, header.value)
      if (header.value === 'top_sale_rep') {
        return this.$formatStaff(row) || '--'
      } else if (cellValue === null) {
        return this.$t('pages.reports.statistics.all.null')
      } else if (header.type === 'currency') {
        const value = parseFloat(cellValue)
        return this.$formatCurrency(value, this.row.currency)
      } else {
        return cellValue
      }
    }
  }
}
</script>

<style scoped>
.dropdown-group-table {
  min-height: 7rem;
}

.pc-header {
  font-weight: bolder;
  margin-bottom: 5px;
}

.pc-row {
  display: flex;
  justify-content: space-between;
}

.pc-cell {
  padding: 2px;
}

.transaction-align {
  text-align: end;
}
</style>

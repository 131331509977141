<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      class="statistics-resource-table"
      do-route-filters
      expanding-row
      headers-filterable
      no-meta-check
      prune-search-filters
      resource="customersV1"
      route-base="/reports/statistics/customers"
      show-filter
      sortable
      :buttons="computedButtons"
      :custom-resource="customResource()"
      :headers-config="headersConfig"
      :headers="headers"
      :locale="locale"
      :resource-query="resourceQuery"
      :row-key="(item) => `${item.id}-${item.currency}`"
      :search-filters="filtersList"
      :show-operations="false"
      :transform-fetched-data="transformData"
      @headers-config="handleHeadersConfig"
      @loading-error="handleLoadingError"
      @search-filter-submit="
        $ampli.eventWithBaseProps('statisticsFiltersSearchButtonClick')
      "
    >
      <template #expanding-row="props">
        <expanding-row :row="props.row" />
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import Decimal from 'decimal.js-light'
import { mapGetters } from 'vuex'
import qs from 'qs'
import ExpandingRow from './expanding-row'
import datatableHeadersConfig from '@/mixins/datatable-headers-config'
import {
  applyFiltersBeforeRouteEnter,
  formatDateRange,
  getDateTimeFormat,
  getRangeFor
} from '@/utils/date'
import { useExportsStore } from '@/store/exports'

export default {
  metaInfo() {
    return {
      title: this.$t('pages.reports.statistics.customers.customers')
    }
  },
  components: {
    ExpandingRow
  },
  beforeRouteEnter: (to, from, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  beforeRouteUpdate(to, from, next) {
    this.$emit('route-filter', safeGet(qs.parse(to.query), 'filter') || {})
    next()
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { headersConfig, handleHeadersConfig } = datatableHeadersConfig(
      'settings.headerFilters.reports.statistics.customers'
    )
    return {
      headersConfig,
      handleHeadersConfig
    }
  },
  data() {
    return {
      isLegacy: true,
      loadingExport: false,
      filters: {},
      width: 500,
      headers: [
        {
          field: 'customer_number',
          label: this.$t('pages.reports.statistics.customers.customer_number'),
          truncate: true,
          minWidth: 150,
          fallback: '-'
        },
        {
          field: 'lastname',
          label: this.$t('pages.customers.all.headers.lastname'),
          truncate: true,
          minWidth: 120,
          fallback: '-'
        },
        {
          field: 'firstname',
          label: this.$t('pages.customers.all.headers.firstname'),
          truncate: true,
          minWidth: 120,
          fallback: '-'
        },
        {
          field: 'avg_qu_cart_items',
          label: this.$t(
            'pages.reports.statistics.customers.avg_qu_cart_items'
          ),
          minWidth: 200,
          truncate: true,
          align: 'right',
          sortType: 'number',
          fallback: '-'
        },
        {
          field: 'total_transactions',
          label: this.$t(
            'pages.reports.statistics.customers.total_transactions'
          ),
          minWidth: 160,
          truncate: true,
          align: 'right',
          sortType: 'number',
          fallback: '-'
        },
        {
          field: 'total_revenue',
          label: this.$t('pages.reports.statistics.customers.total_revenue'),
          minWidth: 160,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.total_revenue)) {
              return this.$formatCurrency(row.total_revenue, row.currency)
            }

            return '-'
          },
          sortType: 'currency',
          fallback: '-'
        },
        {
          field: 'total_net_revenue',
          label: this.$t(
            'pages.reports.statistics.customers.total_net_revenue'
          ),
          minWidth: 200,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.total_net_revenue)) {
              return this.$formatCurrency(row.total_net_revenue, row.currency)
            }

            return '-'
          },
          sortType: 'currency',
          fallback: '-'
        },
        {
          field: 'total_discount',
          label: this.$t('pages.reports.statistics.customers.total_discount'),
          minWidth: 140,
          truncate: true,
          formatter: (row) => {
            if (Number.isFinite(row.total_discount)) {
              return this.$formatCurrency(row.total_discount, row.currency)
            }

            return '-'
          },
          align: 'right',
          sortType: 'currency',
          fallback: '-'
        }
      ],
      revenueOptions: [
        {
          label: this.$t('pages.reports.statistics.customers.revenue'),
          value: 'revenue',
          type: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.customers.net_revenue'),
          value: 'net_revenue',
          type: 'currency'
        }
      ],
      summary: null,
      buttons: [
        {
          type: 'custom_export',
          scopes: ['reports_statistics:customers:export'],
          label: this.$t('common.interactions.buttons.export'),
          clickHandler: ({ resourceOptions }) => {
            this.handleExport({ resourceOptions })
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      branchNumber: 'Config/getCurrentBranchNumber',
      locale: 'Config/getLocale',
      currentLocation: 'Config/getCurrentLocation',
      defaultDateSelected: 'Config/getDefaultDateSelected'
    }),
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter

      return parsedQuery || {}
    },
    parsedDate() {
      return this.parsedQuery.date || {}
    },
    date() {
      return {
        start: new Date(this.parsedDate.start),
        end: new Date(this.parsedDate.end),
        showDateText: true
      }
    },
    resourceQuery() {
      return {
        legacy: this.isLegacy,
        branch_number: this.branchNumber || undefined,
        end: this.parsedDate.end || undefined,
        start: this.parsedDate.start || undefined,
        customer_number: this.parsedQuery.customer_number || undefined,
        branch_group: this.parsedQuery.branch_group || undefined,
        company: this.parsedQuery.company || undefined
      }
    },
    filtersList() {
      return [
        {
          name: 'customer_number',
          type: 'remote-search-select',
          label: this.$t('pages.reports.statistics.customers.customer'),
          placeholder: this.$t('common.inputs.placeholders.search'),
          resource: 'customersV1',
          fetchHandler: 'getAll',
          optionsValue: 'customer_number',
          computeName: this.$formatCustomer,
          modifyQuery: (q) => ({
            q,
            fields: ['customer_number', 'firstname', 'lastname']
          })
        },
        {
          name: 'company',
          label: this.$t('pages.reports.statistics.customers.company'),
          type: 'input'
        },
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.currentLocation,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        {
          name: 'date',
          prop: ['start', 'end'],
          type: 'daterange',
          dateTimeMode: true,
          label: this.$t('pages.transactions.all.filters.date.label'),
          closable: false,
          autoClose: false,
          formatValue: (value) => formatDateRange(value, getDateTimeFormat()),
          default: getRangeFor[this.defaultDateSelected]?.(),
          modifyFilter: (filterObject) => ({
            start: filterObject.start,
            end: filterObject.end
          })
        }
      ]
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    customResource() {
      const self = this
      return {
        getAll(params) {
          return new Promise((resolve, reject) => {
            th.analytics()
              .getCustomersReport(self.resourceQuery)
              .then(({ data }) => {
                const list =
                  data &&
                  data.find(
                    (d) => safeGet(d, 'metric.job') === 'reports_customers'
                  )
                const summary =
                  data &&
                  data.find(
                    (d) =>
                      safeGet(d, 'metric.job') === 'reports_customers.summary'
                  )

                if (summary && summary.values) {
                  if (Array.isArray(summary.values)) {
                    self.summary = summary.values
                  } else {
                    self.summary = [summary.values]
                  }
                }

                if (list && list.values) {
                  resolve({
                    data: list.values
                  })
                }

                resolve({
                  data: []
                })
              })
              .catch((error) => reject(error))
          })
        }
      }
    },
    handleLoadingError() {},
    transformData(data) {
      return data
    },
    getCellValue(header, row) {
      const cellValue = safeGet(row, header.value)
      const type = header.type
      if (header.value === 'sales_rep') {
        return this.$formatStaff(row) || '--'
      } else if (cellValue === null) {
        return this.$t('pages.reports.statistics.customers.null')
      } else if (type === 'number') {
        const d = new Decimal(cellValue)
        return d.toDecimalPlaces(4).toString()
      } else {
        return cellValue
      }
    },
    async handleExport({ resourceOptions }) {
      this.$ampli.eventWithBaseProps('statisticsExportButtonClick')
      const query = {
        ...this.resourceQuery,
        ...resourceOptions.query,
        format: 'csv'
      }
      try {
        const {
          data
        } = await th
          .analyticsHandlersV1()
          .analytics.reports.AnalyticsReportsCustomers.getAll(query)

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.reports.statistics.customers.customers',
            date: new Date(),
            action: {
              entity: 'analyticsHandlersV1',
              path: 'analytics.reports.AnalyticsReportsCustomers',
              handler: 'export',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.reports.statistics.customers.customers')
          })
        })
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.$refs.table.refresh()
      })
    }
  }
}
</script>

<style scoped>
.statistics-resource-table :deep(.el-table .el-table__expanded-cell) {
  margin: 0 !important;
  padding: 1rem 1rem 2.5rem 1rem !important;
}
</style>
